<template>
  <b-card> </b-card>
</template>

<script>
  import vSelect from 'vue-select';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

  import {
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BSpinner,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend
  } from 'bootstrap-vue';

  export default {
    components: {
      vSelect,
      BRow,
      BCol,
      BForm,
      BCard,
      BButton,
      BSpinner,
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      ValidationObserver,
      ValidationProvider,
      ToastificationContent
    },
    data() {
      return {
        currentSite: localStorage.getItem('currentSite'),
        timeZoneOptions: [],
        timeZone: '',
        'languageOptions': [
          { label: 'Inglês', value: 'en-US' },
          { label: 'Português', value: 'pt-BR' }
        ],
        language: '',
        settingId: '',

        // show-hide over-lay
        sendingForm: false
      };
    },
    beforeCreate() {},
    created() {
      this.$http
        .get(`/api/${this.$router.currentRoute.params.prefix}/time-zones/list`)
        .then((response) => {
          let timeZones = response.data;
          timeZones.forEach((timeZone) => {
            this.timeZoneOptions.push({
              label: `${timeZone.simple_name} | ${timeZone.current_utc_offset}`,
              value: timeZone.name
            });
          });
        });

      this.$http
        .get(
          `/api/${this.$router.currentRoute.params.prefix}/setting?site_guid=${this.currentSite}`
        )
        .then((response) => {
          this.timeZone = response.data.Settings.time_zone;
          this.language = response.data.Settings.language;
          this.settingId = response.data.Settings.id;
        });
    },
    methods: {
      settingsFormSubmitted() {
        this.sendingForm = true;
        this.$http
          .put(`/api/${this.$router.currentRoute.params.prefix}/setting/${this.settingId}`, {
            time_zone: this.timeZone,
            language: this.language
          })
          .then((response) => {
            this.sendingForm = false;
            this.showToast(
              'Good job!',
              'SmileIcon',
              'Settings has been successfully updated!',
              'success'
            );
            this.$router.push({ name: 'home' });
          })
          .catch((error) => {
            this.sendingForm = false;
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
          });
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      }
    },

    directives: {
      Ripple
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-sweetalert.scss';

  @import '~@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
